import { GridForm, Markdown } from '@codecademy/gamut';
import React from 'react';

import { passwordTips } from '../../Register/elements';
import { RegistrationFormValues } from './types';
import { VALIDATORS } from '../../Register/validators';
import { businessActivateAccount } from './helpers';

interface SetPasswordFormProps {
  redirectPath: string;
}

export const SetPasswordForm: React.FC<
  React.PropsWithChildren<SetPasswordFormProps>
> = ({ redirectPath }) => {
  const onSubmit = async (values: RegistrationFormValues) => {
    const response = await businessActivateAccount(values);
    if (response.message === 'success') {
      window.location.assign(redirectPath);
    }
  };

  return (
    <GridForm
      fields={[
        {
          infotip: {
            info: <Markdown text={passwordTips} />,
          },
          id: 'set-password',
          name: 'password',
          label: 'Password',
          size: 12,
          type: 'password',
          validation: VALIDATORS.password({}),
        },
        {
          hideLabel: true,
          type: 'checkbox',
          name: 'optIntoMarketingEmails',
          description:
            'I would like to receive emails about new course offerings and helpful coding tips. (optional)',
          label: '',
          size: 12,
        },
      ]}
      validation="onTouched"
      onSubmit={onSubmit}
      submit={{
        contents: 'Set password',
        size: 12,
        position: 'stretch',
        disabled: ({ isSubmitting }) => isSubmitting,
      }}
      data-testid="business-set-password-form"
    />
  );
};
