import { cfAccessHeaders } from '@mono/data-cf-access-headers';
import Cookies from 'universal-cookie';
import { RegistrationFormValues } from './types';

interface ActivateAccountResponse {
  success: boolean;
  ok: boolean;
  status: number;
  message?: string;
}

const businessAccountActivationUrl = `/business/activate_account`;

export const businessActivateAccount = async (
  values: RegistrationFormValues
): Promise<ActivateAccountResponse> => {
  const { password, optIntoMarketingEmails } = values;
  const cookies = new Cookies();
  const response: Response = await fetch(businessAccountActivationUrl, {
    method: 'POST',
    credentials: 'include',
    headers: {
      'Content-Type': 'application/json',
      Accept: 'application/json',
      'X-CSRF-Token': cookies.get('CSRF-TOKEN'),
      ...cfAccessHeaders,
    },
    body: JSON.stringify({
      user: {
        password,
        mail_silence: !optIntoMarketingEmails,
      },
    }),
  });

  return response.json();
};
