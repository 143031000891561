import { Box } from '@codecademy/gamut';
import { ColorMode } from '@codecademy/gamut-styles';
import { userIsLoggedIn, useUser } from '@mono/data-user';
import { GuardedCaptchaProvider } from '@mono/ui-captcha';
import { useGlobalHead, useGlobalMeta } from '@mono/util-dom';
import React, { ComponentProps, useMemo, useState } from 'react';

import { LoggedIn } from '../LoggedIn';
import { Login } from '../Login';
import { Register } from '../Register';
import type {
  EmbeddediFrame,
  LogInOrRegisterVariant,
  TrackingData,
} from './types';

export interface LoginOrRegisterProps
  extends Omit<ComponentProps<typeof Box>, 'onSubmit'>,
    EmbeddediFrame {
  variant?: LogInOrRegisterVariant;
  redirectUrl?:
    | string
    | {
        login: string;
        register: string;
      };
  renderTitle?: (
    ref?: React.RefObject<HTMLElement>,
    showLogin?: boolean
  ) => React.ReactNode;
  renderSubtitle?: (showLogin: boolean) => React.ReactNode;
  buttonText?: string;
  disableFocusOnMount?: boolean;
  trackingData?: TrackingData;
  hideLoggedIn?: boolean;
  colorMode?: 'light' | 'dark';
  captureMobile?: boolean;
  includeCaptcha?: boolean;
  showLoginAsDefault?: boolean;
  onSubmit?: (isLogin: boolean) => void;
}
export const LoginOrRegister: React.FC<LoginOrRegisterProps> = ({
  buttonText,
  colorMode = 'light',
  disableFocusOnMount,
  embedded,
  hideLoggedIn,
  redirectUrl,
  renderTitle,
  renderSubtitle,
  trackingData,
  variant = 'floating',
  captureMobile,
  minHeight,
  includeCaptcha = true,
  showLoginAsDefault = false,
  onSubmit,
  ...boxProps
}) => {
  const [showLogin, setShowLogin] = useState(showLoginAsDefault);

  const user = useUser();

  const loggedInUser = useMemo(() => {
    if (!user) {
      return null;
    }
    if (!userIsLoggedIn(user)) {
      return null;
    }

    return user;
  }, [user]);

  const title = loggedInUser
    ? 'Continue to Dashboard'
    : showLogin
    ? 'Log in'
    : 'Register';

  const head = useGlobalHead({});
  const meta = useGlobalMeta({
    title,
    description:
      "Codecademy is the easiest way to learn how to code. It's interactive, fun, and you can do it with your friends.",
  });

  if (loggedInUser && hideLoggedIn) return null;

  const Wrapper = includeCaptcha ? GuardedCaptchaProvider : React.Fragment;

  const loginRedirectUrl =
    typeof redirectUrl === 'string' ? redirectUrl : redirectUrl?.login;

  const registerRedirectUrl =
    typeof redirectUrl === 'string' ? redirectUrl : redirectUrl?.register;

  return (
    <Wrapper>
      {embedded && (
        <>
          {head}
          {meta}
        </>
      )}
      <ColorMode mode={colorMode}>
        <Box minHeight={minHeight ?? 570} {...boxProps}>
          {loggedInUser ? (
            <LoggedIn variant={variant} user={loggedInUser} />
          ) : showLogin ? (
            <Login
              embedded={embedded}
              variant={variant}
              toggleView={() => setShowLogin(false)}
              redirectUrl={loginRedirectUrl ?? '/learn'}
              renderTitle={renderTitle}
              renderSubtitle={renderSubtitle}
              buttonText={buttonText}
              disableFocusOnMount={disableFocusOnMount}
              trackingData={trackingData}
              onSubmit={onSubmit}
            />
          ) : (
            <Register
              embedded={embedded}
              variant={variant}
              toggleView={() => setShowLogin(true)}
              redirectUrl={registerRedirectUrl ?? '/welcome/find-a-course'}
              renderTitle={renderTitle}
              renderSubtitle={renderSubtitle}
              buttonText={buttonText}
              disableFocusOnMount={disableFocusOnMount}
              trackingData={trackingData}
              captureMobile={captureMobile}
              minHeight={minHeight}
              onSubmit={onSubmit}
            />
          )}
        </Box>
      </ColorMode>
    </Wrapper>
  );
};
