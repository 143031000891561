import { Box, FlexBox, GridBox, StrokeButton } from '@codecademy/gamut';
import { css, states } from '@codecademy/gamut-styles';
import { StyleProps } from '@codecademy/variance';

import styled from '@emotion/styled';

import { ReactFCWithChildren } from '@mono/util-typings';
import { OauthButtonsProps } from '.';

// these seemingly arbitrary media queries are specifically for the embedded version of this component

export const StyledGridBox = styled(GridBox.withComponent('ul'))(
  css({
    gridAutoFlow: 'column',
    columnGap: 12,
    '@media (max-width: 375px)': { columnGap: 8 },
    '@media (max-width: 280px)': { gridAutoFlow: 'row' },
    listStyle: 'none',
    pl: 0,
    mb: 24,
  })
);

const StyledFlexBox = styled(FlexBox.withComponent('ul'))(
  css({
    listStyle: 'none',
    pl: 0,
  })
);

export const OAuthButtonsWrapper: ReactFCWithChildren<
  Pick<OauthButtonsProps, 'isFancy'>
> = ({ children, isFancy }) => {
  if (isFancy)
    return <StyledGridBox gridAutoColumns="1fr">{children}</StyledGridBox>;

  return <StyledFlexBox wrap>{children}</StyledFlexBox>;
};

const StrokeButtonBaseStyles = css({
  '@media (max-width: 385px)': { px: 4 },
  px: 16,
  py: 4,
  mb: 8,
  mr: 8,
  height: { _: 43, xs: 53 },
  width: { _: 50, xs: 65 },
  backgroundColor: 'white',
});

const StrokeButtonStateStyles = states({
  isFancy: {
    p: 0,
    height: { _: 48, xs: 48 },
    width: { _: '100%', xs: '100%' },
  },
});

export const StyledStrokeButton = styled(StrokeButton)<
  StyleProps<typeof StrokeButtonStateStyles>
>(StrokeButtonBaseStyles, StrokeButtonStateStyles);

export const StyledToolTipContents = styled(Box)(
  states({
    embedded: {
      '@media (max-width: 343px)': { width: '60vw' },
      width: '70vw',
    },
  })
);
