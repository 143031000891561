import debounce from 'lodash/debounce';
import isEmpty from 'lodash/isEmpty';
import mapValues from 'lodash/mapValues';
import Cookies from 'universal-cookie';

import { cfAccessHeaders } from '@mono/data-cf-access-headers';
import { ErrorMessages, UserSubmitKey } from './types';

type ServerError = keyof typeof SERVER_ERRORS;

interface ValidationResponse {
  errors?: Record<string, ServerError | ServerError[]> | undefined;
}

const SERVER_ERRORS = {
  weak: ErrorMessages.WEAK,
  breach: ErrorMessages.BREACH,
  length: ErrorMessages.LENGTH,
  invalid: ErrorMessages.VALID_EMAIL,
  'is invalid': ErrorMessages.VALID_EMAIL,
  'is already taken': ErrorMessages.UNIQUE_EMAIL,
};

interface ServerValidatorParams {
  errorMessages?: Record<string, string>;
}

interface ServerValidator {
  name: string;
  endpoint: string;
  formatRequest: (input: string) => Record<string, unknown>;
  errors: Record<string, string>;
}

export const extractValidationErrors = (
  currentErrors: Record<string, ServerError | ServerError[]> | undefined,
  errorMessages: Record<string, string>
): Record<string, string> | null => {
  if (!currentErrors || isEmpty(Object.values(currentErrors))) return null;
  return mapValues(currentErrors, (error) =>
    Array.isArray(error)
      ? errorMessages[error[0]]
      : errorMessages[error] || error
  );
};

const createServerValidator = ({
  name,
  endpoint,
  formatRequest,
  errors,
}: ServerValidator) => {
  let currentInput: string;
  const validator = debounce(
    async (input: string, resolve: (error: string | undefined) => void) => {
      const cookies = new Cookies();
      const res = await fetch(endpoint, {
        method: 'POST',
        credentials: 'include',
        headers: {
          'Content-Type': 'application/json',
          Accept: 'application/json',
          'X-CSRF-Token': cookies.get('CSRF-TOKEN'),
          ...cfAccessHeaders,
        },
        body: JSON.stringify(formatRequest(input)),
      });
      const responseBody = (await res.json()) as ValidationResponse;
      if (res.ok) {
        resolve(undefined);
      } else {
        resolve(
          currentInput === input
            ? extractValidationErrors(responseBody.errors, errors)?.[name]
            : undefined
        );
      }
    },
    250,
    { leading: true, trailing: true }
  );

  return async (input: string) => {
    currentInput = input;
    return await new Promise<string | undefined>((resolve) => {
      validator(input, resolve)?.catch((err: string) => err);
    });
  };
};

export const BASE_EMAIL_VALIDATOR = {
  required: ErrorMessages.VALID_EMAIL,
  pattern: {
    value: /^[^@\s]+@[^@\s\.]+\.[^@\s]{2,}$/, // eslint-disable-line no-useless-escape
    message: ErrorMessages.VALID_EMAIL,
  },
};

export const VALIDATORS = {
  [UserSubmitKey.EMAIL]: ({ errorMessages }: ServerValidatorParams) => ({
    ...BASE_EMAIL_VALIDATOR,
    validate: createServerValidator({
      name: 'email',
      endpoint: '/register/validate',
      formatRequest: (input) => ({ user: { email: input } }),
      errors: { ...SERVER_ERRORS, ...errorMessages },
    }),
  }),
  [UserSubmitKey.PASSWORD]: ({ errorMessages }: ServerValidatorParams) => ({
    required: ErrorMessages.LENGTH,
    minLength: {
      value: 6,
      message: ErrorMessages.LENGTH,
    },
    maxLength: {
      value: 128,
      message: ErrorMessages.LENGTH,
    },
    validate: createServerValidator({
      name: 'password',
      endpoint: '/register/validate',
      formatRequest: (input) => ({ user: { password: input } }),
      errors: { ...SERVER_ERRORS, ...errorMessages },
    }),
  }),
  [UserSubmitKey.MOBILE]: () => ({
    required: ErrorMessages.VALID_MOBILE,
    pattern: {
      value: /^\d{10}$/,
      message: ErrorMessages.VALID_MOBILE,
    },
  }),
};
