import { createTracker } from '@mono/tracking';
import { isOnServer } from '@mono/util-env';

const tracker = createTracker({
  apiBaseUrl: isOnServer() ? '' : process.env.NEXT_PUBLIC_BASE_PATH,
  verbose: !['production', 'test'].includes(process.env.NODE_ENV),
});

const source_codebase = 'portal-app';

export const trackEvent: typeof tracker.event = (category, event, data) =>
  tracker.event(category, event, { ...data, source_codebase });

export const trackUserClick: typeof tracker.click = (data) =>
  tracker.click({ ...data, source_codebase });

export const trackUserVisit: typeof tracker.visit = (data) =>
  tracker.visit({ ...data, source_codebase });

export const trackUserImpression: typeof tracker.impression = (data) =>
  tracker.impression({ ...data, source_codebase });
