import { Anchor, Box, Text } from '@codecademy/gamut';
import { ComponentProps, useEffect, useRef, useState } from 'react';

import styled from '@emotion/styled';
import { LoginOrRegisterProps } from '../LoginOrRegister';
import {
  UserFormAlertArea,
  UserFormBodyWrapper,
  UserFormFooter,
  UserFormWrapper,
  getAlert,
} from '../LoginOrRegister/elements';
import { TrackingData } from '../LoginOrRegister/types';
import {
  SuccessCallbackProp,
  redirectAfterSignup,
} from '../LoginOrRegister/utils';
import { UserForm, embedTitle, standardTitle } from './elements';

export interface RegisterProps
  extends Pick<LoginOrRegisterProps, 'embedded' | 'variant'>,
    Pick<ComponentProps<typeof Box>, 'minHeight'> {
  buttonText?: string;
  captureMarketingEmail?: boolean;
  currentPage?: string;
  defaultValues?: {
    email?: string;
    password?: string;
  };
  isStudent?: boolean;
  locationType?: string;
  onSuccess?: (body: SuccessCallbackProp) => void;
  redirectUrl?: string;
  reminderEmailData?: {
    content_path: string;
    content_title: string;
    content_pro_exclusive: boolean;
    content_is_path: boolean;
  };
  titleAs?: ComponentProps<typeof Text>['as'];
  renderTitle?: (
    ref?: React.RefObject<HTMLElement>,
    showLogin?: boolean
  ) => React.ReactNode;
  renderSubtitle?: (showLogin: boolean) => React.ReactNode;
  toggleView?: () => void;
  trackingData?: TrackingData;
  disableFocusOnMount?: boolean;
  captureMobile?: boolean;
  onSubmit?: (isLogin: boolean) => void;
}

export const businessSSOSignUpUrl = (redirectPath?: string) =>
  `/business/sso/register${
    redirectPath ? '?redirect=' + encodeURIComponent(redirectPath) : ''
  }`;

export enum RegisterFlow {
  MOBILE_NO_STEP = 1,
  ACCOUNT_DETAILS_STEP = 2,
}

const StepTitleText = styled(Text)`
  font-family: 'Suisse';
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 21px;
`;

export const Register: React.FC<RegisterProps> = ({
  buttonText = 'Sign up',
  currentPage,
  disableFocusOnMount,
  embedded,
  onSuccess = redirectAfterSignup,
  redirectUrl,
  reminderEmailData,
  renderTitle,
  renderSubtitle,
  titleAs = 'h1',
  toggleView,
  trackingData,
  variant = 'floating',
  minHeight,
  onSubmit,
  captureMobile,
}) => {
  const [submissionError, setSubmissionError] = useState<string | undefined>();
  const titleRef = useRef<HTMLElement>(null);
  const [currentStep, setCurrentStep] = useState(RegisterFlow.MOBILE_NO_STEP); // only used for captureMobile case

  const setErrorMsg = (msg: string | undefined) => {
    setSubmissionError(msg);
  };

  const renderNextStep = () => {
    if (currentStep !== RegisterFlow.ACCOUNT_DETAILS_STEP) {
      setCurrentStep(RegisterFlow.ACCOUNT_DETAILS_STEP);
      titleRef.current?.focus();
    }
  };

  useEffect(() => {
    if (!disableFocusOnMount) titleRef.current?.focus();
  }, [disableFocusOnMount]);

  const isFancy = variant === 'fancy';

  const renderStepNumber = () => {
    //only in case of mobile capture - has step wise flow
    if (captureMobile) {
      return <StepTitleText>{`STEP ${currentStep} OF 2`}</StepTitleText>;
    }
    return null;
  };

  const getFormMinHt = () => {
    if (minHeight && minHeight !== '0px') return minHeight.toString();
    else if (captureMobile && currentStep === RegisterFlow.MOBILE_NO_STEP)
      return '400px';
    return undefined;
  };

  return (
    <>
      {submissionError && isFancy && (
        <UserFormAlertArea onClose={() => setSubmissionError(undefined)}>
          {getAlert({ message: submissionError, type: 'error' })}
        </UserFormAlertArea>
      )}
      <UserFormWrapper
        embedded={embedded}
        variant={variant}
        minHeight={getFormMinHt()}
      >
        <UserFormBodyWrapper variant={variant}>
          {renderStepNumber()}
          {renderTitle?.(titleRef, false) ?? (
            <Text
              as={titleAs}
              ref={titleRef}
              tabIndex={-1}
              variant="title-md"
              fontSize={{
                _: 26,
                md: 34,
              }}
            >
              {isFancy ? embedTitle : standardTitle}
            </Text>
          )}
          {renderSubtitle?.(false)}
          <UserForm
            currentStep={currentStep}
            renderNextStep={renderNextStep}
            buttonText={buttonText}
            currentPage={currentPage}
            onSuccess={onSuccess}
            redirectUrl={redirectUrl}
            reminderEmailData={reminderEmailData}
            trackingData={trackingData}
            captureMobile={captureMobile}
            submissionError={submissionError}
            setErrorMsg={setErrorMsg}
            variant={variant}
            embedded={embedded}
            onSubmit={onSubmit}
          />
        </UserFormBodyWrapper>
        {toggleView ? (
          <UserFormFooter variant={variant} view="register">
            <Anchor
              variant={isFancy ? 'standard' : 'inline'}
              onClick={toggleView}
            >
              Log in
            </Anchor>
          </UserFormFooter>
        ) : null}
      </UserFormWrapper>
    </>
  );
};
