import Uri from 'jsuri';

type QueryParams = { [key: string]: string | string[] | boolean | undefined };

export const getUrl = (path: string, params: QueryParams) => {
  const search = new URLSearchParams();

  Object.entries(params).forEach(([key, value]) => {
    if (value !== undefined) search.append(key, value.toString());
  });

  const searchString = search.toString();

  const paramsString =
    params && searchString.length
      ? `${path.indexOf('?') === -1 ? '?' : '&'}${searchString}`
      : '';

  return `${path}${paramsString}`;
};

export const getFullURLFromPath = (path: string) =>
  `https://codecademy.com${path.startsWith('/') ? '' : '/'}${path}`;

export const addParamsAsHash = (
  url: string,
  paramHash: Record<string, boolean | number | string>
) => {
  const uri = new Uri(url);
  Object.keys(paramHash).forEach((k) => {
    if (paramHash[k]) {
      uri.deleteQueryParam(k);
      uri.addQueryParam(k, paramHash[k]);
    }
  });
  return uri.toString();
};

function removeTrailingSlashes(path: string) {
  return path === '/' ? path : path.replace(/(\/+)$/, '');
}

const isEncoded = (uri: string) => {
  uri = uri || '';
  return uri !== decodeURIComponent(uri);
};

const fullyDecodeURI = (uri: string) => {
  while (isEncoded(uri)) {
    uri = decodeURIComponent(uri);
  }
  return uri;
};

export function safeRedirectUrl(rawUri?: string) {
  if (!rawUri) return '';
  let decodedUri;
  try {
    decodedUri = fullyDecodeURI(rawUri || '');
  } catch (_error) {
    decodedUri = rawUri;
  }
  const uri = new Uri(decodedUri);

  if (typeof window !== 'undefined') {
    const currentUri = new Uri(window.location.href);
    const path = currentUri
      .setPath(uri.path())
      .setHost(window.location.hostname)
      .setQuery(uri.query())
      .toString();

    return removeTrailingSlashes(path);
  }
  return '';
}
